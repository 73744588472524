@font-face {
    font-family: "acumin-pro", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "acumin-pro-condensed", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}