@import 'fonts';

$maroon: #6b0f0f;
$brown-ruddy: #332E25;
$brown: #554B3D;
$tan: #967445;
$tan-light: #FBFBF8;
$tan-grey: #B3AEA8;
$grey-dark-1: #E0DFDA;
$grey-dark: #979797;
$grey-dark-2: #554B3C;
$grey: #dad5cd;
$grey-light: #edece6;
$orange: #ad441e;
$beige: #FAF9F5;
$white-off: #FAF9F5;

$sizing-tt: 1.2s;
$hover-lin: .15s linear;
$hover-ease: .2s ease-in-out;

@mixin background-image($path, $file) {
    background-image: url('/apps/default/asset/img/' + $path + '/' + $file);
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin tablet-mobile {
    @include tablet {
        @content;
    }
    @include mobile {
        @content;
    }
}

@mixin desktop-tablet {
    @include desktop {
        @content;
    }
    @include tablet {
        @content;
    }
}

@mixin mobile-large {
    @media screen and (max-width: 767px) and (min-width: 380px) {
        @content;
    }
}

@mixin acumin {
    font-family: 'acumin-pro';
}

@mixin acumin-light {
    @include acumin;
    font-weight: 100;
}

@mixin acumin-bold {
    @include acumin;
    font-weight: bold;
}

@mixin serious-acumin {
    font-family: 'acumin-pro-consensed';
}
