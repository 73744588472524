@import '../../scss/mixins';

.selectfix {
    width: 150px;
    height: 42px;
    border: 1px solid #dad5cd;
    border-radius: 2px !important;
    outline: 0;
    font-size: 0.88rem;
    line-height: 1;
    overflow: hidden;

    &.state {
        height: 40px !important;
    }


    &:before {
        display: none;
    }

    &:after {
        display: none;
    }

    .input--select {
        @include background-image('icon', 'icon-ie-dropdown.png');
        background-position: 64% 3px;
        background-repeat: no-repeat;
        width: 140%;
        height: 40px;
        padding-bottom: 0px;
        padding-top: 0px;
        border: 0;
        outline: 0;
        line-height: 1;

        &-state {
            background-position: 40% 2px;
            width: 180%;
        }
    }
}
